import { DateTimePickerComponent } from 'src/app/common/components/date.time.picker/date.time.picker.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccordionComponent } from 'src/app/common/components/accordion/accordion.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QRCodeModule } from 'angularx-qrcode';

import { SpinnerComponent } from 'src/app/common/components/spinner/spinner.component';
import { ButtonComponent } from 'src/app/common/components/button/button.component';
import { SearchFieldComponent } from 'src/app/common/components/search.field/search.field.component';
import { ImageComponent } from 'src/app/common/components/image/image.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FrontendSettingsEffects } from './ngrx.stores/frontend.settings/effects';
import { frontendSettingsReducers } from './ngrx.stores/frontend.settings/reducers';
import { FieldComponent } from 'src/app/common/components/field/field.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateRangePickerComponent } from 'src/app/common/components/date.range.picker/date.range.picker.component';
import { StarComponent } from 'src/app/common/components/star/star.component';
import { GoogleMapComponent } from './components/google.map/google.map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NoContentComponent } from 'src/app/common/components/no.content/no.content.component';
import { SocialShareComponent } from './components/social.share/social.share.component';
import { QrcodeComponent } from 'src/app/common/components/qrcode/qrcode.component';
import { LoadingBlockComponent } from 'src/app/common/components/loading.block/loading.block.component';
import { ClickStopPropagationDirective } from 'src/app/common/directive/click.stop.propagation.directive';
import { ImageSelectorComponent } from 'src/app/common/components/image.selector/image.selector.component';
import { AddressInputComponent } from 'src/app/common/components/address.input/address.input.component';
import { ImageViewerModalComponent } from 'src/app/common/components/image.viewer.modal/image.viewer.modal.component';
import { HorizontalScrollDirectiveDirective } from 'src/app/common/directive/horizontal.scroll.directive.directive';
import {
  ImageLinkFileSelectorComponent
} from 'src/app/common/components/image.link.file.selector/image.link.file.selector.component';
import { AddToTripComponent } from './components/add.to.trip/add.to.trip.component';
import { SuccessCheckmarkComponent } from 'src/app/common/components/success.checkmark/success.checkmark.component';
import { SocialLoginComponent } from './components/social.login/social.login.component';
import { GoogleLoginComponent } from './components/social.login/google.login/google.login.component';
import { FacebookLoginComponent } from './components/social.login/facebook.login/facebook.login.component';
import MultiDateTimePickerComponent
  from 'src/app/common/components/multi.date.time.picker/multi.date.time.picker.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NestedMenuComponent } from 'src/app/common/components/nested.menu/nested.menu.component';
import { MatChipsModule } from '@angular/material/chips';
import { ImMessagerComponent } from './components/im.messager/im.messager.component';
import { MsgBoxComponent } from './components/im.messager/msg.box/msg.box.component';
import { LogoComponent } from './components/logo/logo.component';
import { LoadingDotComponent } from 'src/app/common/components/loading.dot/loading.dot.component';
import { SetRangeComponent } from 'src/app/common/components/set.range/set.range.component';
import { LinkPreviewerComponent } from './components/link.previewer/link.previewer.component';
import { ImMessageBoxComponent } from './components/im.message.box/im.message.box.component';
import { ImageGalleryComponent } from 'src/app/common/components/image.gallery/image.gallery.component';
import { OutsideClickDirective } from '../../common/directive/outside.click.directive';
import { ImageUploaderComponent } from '../../common/components/image.uploader/image.uploader.component';

const COMMON_COMPONENTS = [
  FieldComponent,
  SpinnerComponent,
  ButtonComponent,
  ImageComponent,
  ImageGalleryComponent,
  SearchFieldComponent,
  AccordionComponent,
  DateRangePickerComponent,
  StarComponent,
  GoogleMapComponent,
  NoContentComponent,
  SocialShareComponent,
  QrcodeComponent,
  LoadingBlockComponent,
  LoadingDotComponent,
  DateTimePickerComponent,
  MultiDateTimePickerComponent,
  ImageSelectorComponent,
  ImageLinkFileSelectorComponent,
  AddressInputComponent,
  ImageViewerModalComponent,
  AddToTripComponent,
  SuccessCheckmarkComponent,
  SocialLoginComponent,
  GoogleLoginComponent,
  FacebookLoginComponent,
  NestedMenuComponent,
  ImMessagerComponent,
  ImMessageBoxComponent,
  MsgBoxComponent,
  LogoComponent,
  SetRangeComponent,
  LinkPreviewerComponent,

  // VisitorComponent,
  // VisitorCalendarComponent,
  // InfiniteScrollContainerComponent,
  // VisitorSideBarComponent,
  // VisitorTopBarComponent,
  // VisitorSquareCardComponent,
  // VisitorDefaultCardComponent,
  // VisitorFancyCardComponent,
  // VisitorLongCardComponent,
  // VisitorModernBlockComponent,
  // VisitorVerticalCardComponent,
  // VisitorTopBarComponent,
  // VisitorSideBarComponent,
  // EventDateFilterComponent,
  // VisitorFancyCardComponent,

  ClickStopPropagationDirective,
  HorizontalScrollDirectiveDirective,
  OutsideClickDirective,
  ImageUploaderComponent
];

const THIRD_PARTY_MODULES = [
  RouterModule,
  HttpClientModule,
  HttpClientJsonpModule,

  MatProgressSpinnerModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSidenavModule,
  MatExpansionModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatDialogModule,
  MatSnackBarModule,
  MatRippleModule,
  MatSelectModule,
  MatCheckboxModule,
  MatChipsModule,
  MatRadioModule,
  MatSlideToggleModule,

  GoogleMapsModule,
  QRCodeModule,
  NgxSliderModule,
];

@NgModule({
  declarations: [...COMMON_COMPONENTS],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature('frontendSettings', frontendSettingsReducers),
    EffectsModule.forFeature([FrontendSettingsEffects]),
    ...THIRD_PARTY_MODULES,
  ],
  exports: [...COMMON_COMPONENTS, ...THIRD_PARTY_MODULES],
})
export class SharedModule {
}
