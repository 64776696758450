import { ImageService } from '../../services/image/image.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { clone } from '../../utils/object.extensions';

@Component({
  selector: 'ig-image-uploader',
  templateUrl: './image.uploader.component.html',
  styleUrls: ['./image.uploader.component.scss'],
})
export class ImageUploaderComponent implements OnChanges {

  selectedImageData: any;

  public image: {
    url: string;
    status: 'initial' | 'uploading' | 'uploaded' | 'failed' | 'done';
  };

  @Input() compressWidth = 1200;
  @Input() triggerRemoveImage: any;

  @Output() uploaded = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  constructor(public imageService: ImageService) {     
  }

  public async onImageChange(event: any) {
    const file = event.target.files[0] as File | null;
    await this.uploadFile(file);
  }

  public async onFileDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    await this.uploadFile(file);
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public async uploadFile(file: File | null) {
    if (file && file.type.startsWith('image/')) {
      this.selectedImageData = await this.imageService.compressImageFileToBase64(file);
      this.image = {
        url: clone(this.selectedImageData),
        status: 'initial',
      };

      this.uploadOwnedImagesToAWS(this.image);
    }
  }

  public removeImage(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.image && this.image.url) {
      this.remove.emit(this.image.url);
    }
    this.image = null;
    this.selectedImageData = null;
  }

  public uploadOwnedImagesToAWS(img: any) {
    img.status = 'uploading';
    this.imageService.uploadBase64ImageToAWS(
      img.url,
      this.compressWidth
    ).subscribe((res) => {
      if (res.success) {
        img.url = res.url;
        img.status = 'done';
        this.uploaded.emit(img.url);        
      } else {
        img.status = 'failed';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['triggerRemoveImage']) {
      if(changes['triggerRemoveImage'].currentValue) {
        if (this.image || this.selectedImageData) {
          this.image = null;
          this.selectedImageData = null;
        }
      }      
    }
  }
}
