import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../services/local.storage.service';
import { VisitorService } from '../../services/visitor.service';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/utils/object.extensions';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';
import { catchError, debounceTime, fromEvent, of, tap } from 'rxjs';
import { VisitorPlace } from '../../models/visitor.place';
import { ILinkPreview } from '../link.previewer/link.previewer.component';
import {
  AIModel,
  IAIResponse,
  ITravelAssistantMessage,
} from '../../models/travel.assistant';
import { SocialLoginComponent } from '../social.login/social.login.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TravelBuddySettings } from '../../models/travel.buddy.settings';
import { DOCUMENT } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { SocketService } from '../../services/socket.service';

// const { GoogleGenerativeAI } = require('@google/generative-ai');
// const genAI = new GoogleGenerativeAI('AIzaSyDkHVhpnG5Yg2TM-iJ2mnsQ4jUIL01cEnc');

@Component({
  selector: 'ig-im-message-box',
  templateUrl: './im.message.box.component.html',
  styleUrls: ['./im.message.box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImMessageBoxComponent extends BaseComponent implements OnInit {
  @HostBinding('class') hostClass = 'ig-im';

  right = input<string>();
  left = input<string>();
  bottom = input<string>();
  top = input<string>();

  chatRight: string;
  chatLeft: string;
  chatTop: string;
  chatBottom: string;

  // indicatorRight: string;
  // indicatorLeft: string;
  // indicatorTop: string;
  isIndicatorShowedPageLevel = false;
  indicatorBottom: string;

  /** control the display of the entire chat popup */
  isShow = false;
  isScreenExpanded = false;

  /* isShowLandscreen = true means in the first indication page of the AI chat feature */
  isShowLandscreen = true;
  hasChatViewShowed = false;
  isAskingAfterLogin = false;
  isWaitingForAIResponse = false;
  loadingTips = [
    'Let me think about this!',
    'Let me select the best idea!',
    'On my way!',
    "Hold on tight, we're speeding up!",
    'Making the digital world spin!',
    'Almost there, hang on!',
    'Loading your future experience!',
    "Hold tight, we're about to launch!",
  ];

  // need to specify these Tailwind classes to make sure they are bundled in the final CSS
  style = 'h-[700px] w-[640px] w-[400px] h-[300px]';
  form: FormGroup;
  msg: string;
  assistantId: string;
  threadId: string;
  customProcessor: (data: IAIResponse) => IAIResponse;
  @Input() aiModel: AIModel = 'chatgpt-then-google';
  @Input() public target = '';
  @Input() public place: VisitorPlace;
  /* the content in the landing page is showing up one by one */
  @Input() public showLandscreenContent: boolean[] = [];
  @Input() public travelBuddySettings: TravelBuddySettings;

  @Output() onSendClick: EventEmitter<string> = new EventEmitter();
  @Output() onTryAgain: EventEmitter<string> = new EventEmitter();
  @Output() onMsgCreated: EventEmitter<any> = new EventEmitter();
  @Output() onExpandScreen: EventEmitter<boolean> = new EventEmitter();
  @Output() userChatHistoryReady: EventEmitter<boolean> = new EventEmitter();
  isUserChatHistoryReady = false;
  isLoadingUserChatHistory = false;

  msgHistory: ITravelAssistantMessage[]; // for displaying on the html
  userChatHistory: any[] = []; // load from DB
  hasScrollbar = false;

  streamWordCount = 0;
  aiResTextContent = '';

  /*
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section,
    the top bannder section will be folded after AI has responded
  */
  isAIRespond = false;
  isFolding = false;
  /*
    when you first in the landing page of the AI chat popup, and click on any question to jump to chatting section,
    the top bannder section will be folded, and after the folding completed, isInNormalChat will be set to true
  */
  isInNormalChat = false;
  fallbackLinkPreviewData: ILinkPreview = null;
  isAtbottom = false;

  isFirstTime = false;
  isForDestination = false;

  isShowLogin = false;
  isAppendLoginToChatArea = false;
  isAnonymous = false;
  anonymousUserId: string | undefined = undefined;
  userEmail: string | undefined = undefined;
  emailErrorMsg = '';

  userProfilePicture: string | undefined = undefined;
  isAllowTrackingChat = true; // currently removed the checkbox for asking for user permission, so we will track the conversations for all users

  errorMsgs = [
    `Looks like you've asked a question that's above my pay grade—err. Got another question to throw my way? `,
    `To err is human, to forgive divine, but for your AI Travel Buddy, it's downright awkward! Got another zinger for me? `,
    `Wow, you've managed to stump your AI buddy. You just won a million dollars! Just kidding. Got another question to throw my way? `,
    `You've found a gap in my digital wisdom! Mind tossing another question my way? `,
  ];

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('chatContainer', { static: false }) chatContainer: ElementRef;
  @ViewChild('allowTrackingCbox', { static: false })
  allowTrackingCbox: ElementRef;
  @ViewChild('socialLogin', { static: false })
  socialLogin: SocialLoginComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _localStorage: LocalStorageService,
    private _visitorService: VisitorService,
    private _socketService: SocketService,
    private _snackBar: MatSnackBar,
    private _scriptService: ScriptService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    super({ activatedRoute });

    this.form = this._fb.group({
      msg: [null, [Validators.required]],
      email: [null, []],
    });

    this.resetStepShowing();

    effect(() => {
      // will be called when `right or bottom` is initialized or changes.
      console.log('right', this.right());
      console.log('bottom', this.bottom());
      console.log('left', this.left());
      console.log('top', this.top());

      this.getChatBoxPosition();
      this.getIndicatorPosition();
    });
  }

  ngOnInit() {
    const SCRIPT_PATH = `https://cdn.jsdelivr.net/npm/marked/marked.min.js`;

    if (!this._scriptService.isScriptExist(SCRIPT_PATH)) {
      const scriptElement = this._scriptService.loadJsScript(
        this._renderer,
        SCRIPT_PATH
      );
      scriptElement.onload = () => {
        console.log('markdown script loaded');
      };
      scriptElement.onerror = () => {
        console.log('Could not load the script: ' + SCRIPT_PATH);
      };
    }

    if (this.place) {
      this.fallbackLinkPreviewData = {
        title: this.place.title,
        description:
          this.place.description ||
          `${this.place.category} @ ${this.place.address.address}`,
        image: this.place.cover?.source,
        url: this.place.website,
      };
    }

    this.loadUserChatHistory();
  }

  private processSocketMsg() {
    console.log('build socket msg...');
    let isAnswerShowed = false;

    this._socketService.socket.on(
      'report',
      (report: { message; status; tag }) => {
        const userIdentity = `${this.userId || this.anonymousUserId}|${
          this.userSocialName
        }|${this.hubName}`;
        console.log('userIdentity', userIdentity);
        if (report.tag !== userIdentity) return;

        console.log('report received from socket: ', report);

        const msgEle = this.chatContainer.nativeElement.querySelectorAll(
          'ig-msg-box .ig-im-answer'
        );
        const msgBox = Array.from(msgEle as HTMLElement[]).last();

        if (report.status === 'travelbuddy.message.start') {
          this.streamWordCount = 0;
          this.aiResTextContent = '';
          msgBox.innerHTML = '';
          msgBox.classList.remove('loading-scale');
        }

        if (report.status === 'travelbuddy.message.delta') {
          this.streamWordCount++;
          this.aiResTextContent += report.message;
          msgBox.innerHTML = msgBox.innerHTML + report.message;

          // convert to HTML every 10 words
          if (this.streamWordCount % 10 === 0) {
            const marked = this.processMsg({ answer: this.aiResTextContent });
            msgBox.innerHTML = marked.answer;
          }

          this.scrollToBottom(0);
        }

        // before loading mentioned places
        if (report.status === 'travelbuddy.message.completed') {
          // has <strong> in it means it mentioned places that need to be loaded and show images, then show the loading dot
          if (
            msgBox.innerHTML.toString().includes('<strong') ||
            msgBox.innerHTML.toString().includes('<b')
          ) {
            msgBox.innerHTML += `<div class="flex flex-start"><div class="ig-loading-dot mt-3 !h-8 scale-[0.8]">
                              <div class="ig-loading-dot-wrap">
                                <div></div><div></div><div></div><div></div>
                              </div>
                            </div></div>`;
            this.scrollToBottom(0);
          }
        }

        if (report.status === 'travelbuddy.searching.internet') {
          this.aiResTextContent = '';
          const typings =
            this.chatContainer.nativeElement.querySelectorAll('.ig-ai-typing');
          const typing = Array.from(typings as HTMLElement[]).last();
          if (typing) {
            typing.textContent = typing.textContent.replace(
              'is typing...',
              'is searching Internet 🔍'
            );
          }
          // this.loadingTips = [report.message];
        }

        // when receiving this status, it means the AI has found places that need to be loaded, and this can be considered as a completed message
        if (report.status === 'travelbuddy.place.status') {
          console.log(
            '-------------- travelbuddy.place.status receive --------------'
          );
          // use SetTimeout to make sure other message content has been loaded
          setTimeout(() => {
            const placeStatus = JSON.parse(report.message);
            msgBox.innerHTML = '';
            msgBox.classList.remove('loading-scale');

            console.log(
              '-------------- placeStatus --------------',
              placeStatus
            );
            const processed = this.customProcessor({
              placeSearch: placeStatus.placeSearch,
              placesMentioned: placeStatus.placesMentioned,
              eventsMentioned: placeStatus.eventsMentioned,
              askForEvents: placeStatus.askForEvents,
              askForItinerary: placeStatus.askForItinerary,
              answer: this.processMsg({ answer: this.aiResTextContent }).answer,
            });

            this.createMsg({
              // set strong to bold, which was showing as normal font before the answer is processed
              msg: processed.answer.replaceAll(
                'strong class="font-normal"',
                'strong'
              ),
              type: 'AI',
              model: 'chatgpt',
              suggestions: [],
              questions: ['Please tell more'],
              timeConsumed: 0, //response.timeConsumed,
            } as ITravelAssistantMessage);

            isAnswerShowed = true;
          }, 600);
        }

        // sometimes AI will not send anything but end the stream directly
        if (report.status === 'travelbuddy.end') {
          console.log(
            '-------------- travelbuddy.end -------------- isAnswerShowed: ',
            isAnswerShowed
          );

          if (!isAnswerShowed) {
            this.createMsg({
              type: 'AI_ERROR',
            } as ITravelAssistantMessage);
          }

          isAnswerShowed = false;
        }

        this.cdRef.detectChanges();
      }
    );
  }

  private loadUserChatHistory(isDisplayAfterLoading = false) {
    const user = this.userFromStorage;
    if (user) {
      this.isLoadingUserChatHistory = true;
      this.cdRef.detectChanges();
      // define a date that is 7 days before
      const _7daysBefore = new Date();
      _7daysBefore.setDate(_7daysBefore.getDate() - 7);

      this._visitorService
        .getTravelBuddyConversationByUser(
          this.hubName,
          user.userId,
          _7daysBefore
        )
        .pipe(finalize(() => (this.isLoadingUserChatHistory = false)))
        .subscribe((conversations) => {
          this.userChatHistory =
            conversations && conversations.length ? conversations : [];
          this.isUserChatHistoryReady = true;
          this.msgHistory = [];
          if (isDisplayAfterLoading) {
            this.isShowLogin = false;
            this.displayUserChatHistory();
            this.send(true);
          }
        });
    } else {
      this.isUserChatHistoryReady = true;
      if (isDisplayAfterLoading) {
        this.isShowLogin = false;
        this.send(true);
      }
    }
  }

  get userFromStorage() {
    const email = this._localStorage.getItem('travelbuddy_user_email');
    console.log('email in local storage', email);
    if (email) return { userId: email, avatar: undefined };

    const googleUser = localStorage.getItem('social.login.googleUser');
    console.log('googleUser in LocalStorage', googleUser);

    if (googleUser) {
      const googleUserObj = JSON.parse(googleUser);
      return {
        userId: googleUserObj.userId,
        avatar: googleUserObj.profilePicture,
      };
    }

    const facebookUser = localStorage.getItem('social.login.facebookUser');
    console.log('facebookUser', facebookUser);

    if (facebookUser) {
      const facebookUserObj = JSON.parse(facebookUser);
      return {
        userId: facebookUserObj.userId,
        avatar: facebookUserObj.profilePicture,
      };
    }

    return null;
  }

  get isIndicatorShowed() {
    return this._localStorage.getItem<boolean>('Travelbuddy_is_Prompt_Showed');
  }

  get canShowIndicator() {
    if (!this.travelBuddySettings) return false;

    if (
      this.travelBuddySettings.indicator.onlyShowOnce &&
      !this.isIndicatorShowed
    )
      return true;
    if (
      this.travelBuddySettings.indicator.onlyShowOnce &&
      this.isIndicatorShowed
    )
      return false;

    return !this.isIndicatorShowedPageLevel;
  }

  getChatBoxPosition() {
    const toggleRight = parseFloat(this.right());
    const toggleLeft = parseFloat(this.left());
    const toggleTop = parseFloat(this.top());
    const toggleBottom = parseFloat(this.bottom());
    this.chatRight = toggleRight ? this.right() : 'auto';
    this.chatLeft = toggleLeft ? this.left() : 'auto';
    this.chatTop = toggleTop ? `${toggleTop + 64}px` : 'auto';
    this.chatBottom = toggleBottom ? `${toggleBottom + 64}px` : 'auto';
  }

  getIndicatorPosition() {
    // const toggleRight = parseFloat(this.right());
    // const toggleLeft = parseFloat(this.left());
    // const toggleTop = parseFloat(this.top());
    const toggleBottom = parseFloat(this.bottom());
    // this.chatRight = toggleRight ? this.right() : 'auto';
    // this.chatLeft = toggleLeft ? this.left() : 'auto';
    // this.chatTop = toggleTop ? `${toggleTop + 64}px` : 'auto';
    this.indicatorBottom = toggleBottom ? `${toggleBottom + 195}px` : 'auto';
  }

  checkHasScrollbar() {
    setTimeout(() => {
      if (this.chatContainer) {
        const div = this.chatContainer.nativeElement;
        this.hasScrollbar = div.scrollHeight > div.clientHeight;
      }
    }, 150);

    if (!this.isInNormalChat) {
      this.isAIRespond = this.msgHistory?.any(
        (x) => x.type === 'AI' || x.type === 'AI_ERROR'
      );

      if (this.isAIRespond) {
        this.isFolding = true;
        setTimeout(() => {
          this.isFolding = false;
          this.isInNormalChat = true;
          this.cdRef.detectChanges();
        }, 700);
      }
    }
  }

  closeIndicator() {
    this.isIndicatorShowedPageLevel = true;
    this._localStorage.setItem('Travelbuddy_is_Prompt_Showed', true);
  }

  show() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this._localStorage.setItem('Travelbuddy_is_Prompt_Showed', true);
      this.isIndicatorShowedPageLevel = true;

      this._socketService.init();
      setTimeout(() => {
        this.processSocketMsg();
      });

      // get user chat history
      // show user chat history (which is loaded from DB) when the first time opens TravelBuddy
      // if user close TravelBuddy and open again, loads his chat history from msgHistory
      if (!this.msgHistory?.length) {
        this.displayUserChatHistory();
      } else {
        setTimeout(() => {
          this.scrollToBottom();
          this.userProfilePicture = this.userFromStorage?.avatar;
        }, 100);
      }
    }

    if (!this.isShow) {
      this._socketService.close();
      this.resetStepShowing();
    } else {
      setTimeout(() => {
        let index = 0;
        this.buildStepShowing(index);
      }, 200);
    }
  }

  private displayUserChatHistory() {
    if (this.userChatHistory.length) {
      if (!this.msgHistory) {
        this.msgHistory = [];
      }

      const day = [];

      for (const msg of this.userChatHistory) {
        const dateStr = new Date(msg.createdAt).toLocaleDateString();
        if (!day.includes(dateStr)) {
          day.push(dateStr);

          this.msgHistory.push({
            msg: dateStr,
            type: 'DATETIME',
            name: '',
            questions: [],
            suggestions: [],
          });
        }

        this.msgHistory.push({
          msg: msg.conversation.user,
          type: 'USER',
          name: 'Me',
          questions: [],
          suggestions: [],
        });
        const processed = window['marked'].parse(msg.conversation.ai);
        this.msgHistory.push({
          msg: processed,
          type: 'AI',
          name: this.travelBuddySettings.ui.avatar.name,
          questions: [],
          suggestions: [],
        });
      }

      this.isShowLandscreen = false;
      this.isInNormalChat = true;
      this.cdRef.detectChanges();
      setTimeout(() => {
        this.scrollToBottom();
        this.userProfilePicture = this.userFromStorage?.avatar;
      }, 100);
    }
  }

  buildStepShowing(index: number) {
    if (index < 10) {
      setTimeout(() => {
        this.showLandscreenContent[index] = true;
        this.buildStepShowing(++index);
      }, 50);
    }
  }

  resetStepShowing() {
    for (let i = 0; i < 10; i++) {
      this.showLandscreenContent[i] = false;
    }
  }

  expandScreen() {
    this.isScreenExpanded = !this.isScreenExpanded;
    this.onExpandScreen.emit(this.isScreenExpanded);
  }

  get isUserLoggedIn() {
    return SocialLoginComponent.isUserLoggedIn || !!this.userEmail;
  }

  get userId() {
    return SocialLoginComponent.userId || this.userEmail;
  }

  get userSocialName() {
    return SocialLoginComponent.isUserLoggedIn
      ? SocialLoginComponent.socialName
      : this.userEmail
      ? 'email'
      : '';
  }

  emailKeypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.gotoChatWithEmail();
    }
  }

  gotoChatWithEmail() {
    const email = this.form.get('email').value;
    console.log('email', email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      this.emailErrorMsg = `Please enter a valid email address.`;
      // this._snackBar.open('Please enter a correct email address.', 'Close', {
      //   horizontalPosition: 'center',
      //   verticalPosition: 'bottom',
      //   panelClass: 'ig-error-snackbar',
      // });
      return;
    }

    this.emailErrorMsg = '';
    // this.isShowLogin = false;  // move to loadUserChatHistory
    this.isAppendLoginToChatArea = false;
    this.isAnonymous = false;

    this.userEmail = email;
    this._localStorage.setItem('travelbuddy_user_email', email);
    this.loadUserChatHistory(true);
    // this.send(true);
  }

  async gotoChat(
    msg: string,
    isTryAgain = false,
    process?: (data: IAIResponse) => IAIResponse
  ) {
    if (!msg && !isTryAgain) {
      return;
    }

    this.isShowLandscreen = false;
    this.addScrollingEvent();

    this.customProcessor = process;
    this.msg = msg?.replace('{{target}}', this.target);

    const isAnonmous = this._localStorage.getItem(
      'travelbuddy_is_anonmous_use'
    );
    this.isAnonymous = !!isAnonmous;
    console.log('isAnonmous', this.isAnonymous);

    this.userEmail =
      this._localStorage.getItem('travelbuddy_user_email') || undefined;
    console.log('userEmail', this.userEmail);

    if (!this.isUserLoggedIn && !this.isAnonymous) {
      this.isShowLogin = true;
      this.scrollToTop();

      // has chat history, then add the login box at the bottom
      if (this.msgHistory?.length > 0) {
        this.isAppendLoginToChatArea = true;
        this.isAtbottom = false;
        setTimeout(() => {
          this.scrollToBottom();
          this.checkHasScrollbar();
          // this.disableScroll();
          // otherwise google login might not be shown the second time you see the login view
          this.socialLogin.googleLoginComponent.initializeGoogle();
        }, 300);
      }

      return;
    }

    this.userProfilePicture = SocialLoginComponent.profilePicture;
    this.isShowLogin = false;
    await this.send(false);
  }

  async send(isAfterLogin: boolean) {
    if (this.isWaitingForAIResponse) return;

    // question = question from suggestions, this.msg = user input question, this.msgHistory.last() = last user question, for retrying
    this.msg =
      this.msg ||
      this.msgHistory?.filter((x) => x.type === 'USER')?.last()?.msg;
    if (!this.msg) return;

    const history = this.msgHistory
      ?.filter((x) => x.type !== 'AI_ERROR')
      // .slice(1)
      .map((item) => {
        const type = item.type;
        return {
          msg: item.msg,
          type,
        };
      });

    this.isAskingAfterLogin = isAfterLogin;

    this.askAI(isAfterLogin);

    this.createMsg({
      msg: this.msg,
      type: 'USER',
    } as ITravelAssistantMessage);

    this.createMsg({
      msg: '',
      type: 'AI_THINKING',
      suggestions: [],
      questions: [],
    } as ITravelAssistantMessage);

    this.msg = '';
  }

  keypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      // this.gotoChat();
      this.onSendClick.emit(this.msg);
    }
  }

  scrollToTop() {
    setTimeout(() => {
      this.container &&
        this.container.nativeElement.scrollTo({
          top: 0,
        });
      this.isInNormalChat &&
        this.chatContainer &&
        this.chatContainer.nativeElement.scrollTo({
          top: 0,
        });
      this.cdRef.detectChanges();
    }, 10);
  }

  scrollToBottom(timeout = 100) {
    setTimeout(() => {
      this.container &&
        this.container.nativeElement.scrollTo({
          top: this.container.nativeElement.scrollHeight,
        });
      this.isInNormalChat &&
        this.chatContainer &&
        this.chatContainer.nativeElement.scrollTo({
          top: this.chatContainer.nativeElement.scrollHeight,
        });
      this.cdRef.detectChanges();
    }, timeout);
  }

  addScrollingEvent(): void {
    if (this.isInNormalChat) {
      setTimeout(() => {
        fromEvent(this.chatContainer.nativeElement, 'scroll')
          .pipe(
            debounceTime(10),
            tap((e) => {
              const { scrollTop, scrollHeight, clientHeight } =
                this.chatContainer.nativeElement;
              // calculate if is scrolled to bottom using scrollTop, scrollHeight, clientHeight
              // if user logout with social accounts,  when scrolling to bottom, show login box
              if (scrollHeight - scrollTop < clientHeight + 60) {
                this.isAtbottom = true;
                this.cdRef.detectChanges();
              } else {
                this.isAtbottom = false;
                this.cdRef.detectChanges();
              }
            })
          )
          .subscribe();
      });
    }
  }

  disableScroll() {
    setTimeout(() => {
      this.isInNormalChat &&
        this.chatContainer &&
        this.chatContainer.nativeElement.classList.add('overflow-y-hidden');
      this.cdRef.detectChanges();
    }, 1000);
  }

  enableScroll() {
    setTimeout(() => {
      this.isInNormalChat &&
        this.chatContainer &&
        this.chatContainer.nativeElement.classList.remove('overflow-y-hidden');
      // to hide the login box and update view
      this.cdRef.detectChanges();
    }, 100);
  }

  createMsg(msg: ITravelAssistantMessage): void {
    if (isNullOrUndefined(this.msgHistory)) {
      this.msgHistory = [];
    }

    if (msg.type === 'AI_THINKING') {
      this.msgHistory.push({
        msg: '',
        type: msg.type,
        name: this.travelBuddySettings.ui.avatar.name,
        questions: [],
        suggestions: [],
      });
      this.scrollToBottom();
      return;
    }

    // a non-ai-thinking message, remove previous ai-thinkings
    if (this.msgHistory.last()?.type === 'AI_THINKING') {
      this.msgHistory = this.msgHistory.slice(0, this.msgHistory.length - 1);
    }

    this.msgHistory.push({
      msg: msg.msg,
      type: msg.type,
      name:
        msg.type === 'USER' ? 'Me' : this.travelBuddySettings.ui.avatar.name,
      model: msg.model,
      questions: msg.questions,
      suggestions: msg.suggestions,
      timeConsumed: msg.timeConsumed,
      errorMsg: this.errorMsgs.getRandom(),
    });

    this.scrollToBottom();
    this.checkHasScrollbar();

    this.onMsgCreated.emit({
      lastMsg: msg,
    });
  }

  async askAI(isAfterLogin: boolean) {
    this.isWaitingForAIResponse = true;

    this._visitorService
      .travelBuddyConversation({
        question: this.msg,
        hubName: this.hubName,
        assistantId: this.assistantId,
        threadId: this.threadId,
        userId: this.userId || this.generateUserId(),
        isAnonymous: this.isAnonymous,
        userImg: SocialLoginComponent.profilePicture,
        userEmail: SocialLoginComponent.email || this.userEmail,
        socialName: this.userSocialName,
        userName: SocialLoginComponent.userName,
        cityName: this.travelBuddySettings.cityName,
        isAllowTrackingChat: isAfterLogin
          ? this.isAllowTrackingChat
          : undefined, // for after login, it need to remember if allow tracking chat. Otherwise, for normal chat, set this to undefined
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of({ success: false });
        })
      )
      .subscribe((response) => {
        console.log('subscribe askAI', response);

        this.isWaitingForAIResponse = false;

        if (!response?.success) {
          this.createMsg({
            type: 'AI_ERROR',
          } as ITravelAssistantMessage);
        }

        return;
        console.log(
          '-------------------START PROCESSING---------------------',
          response
        );
        console.log(
          '---------------------Original answer-------------------',
          response.answer
        );
        if (response.answer) {
          let processed = this.processMsg(response);
          this.customProcessor && (processed = this.customProcessor(processed));
          this.assistantId = response.assistantId || this.assistantId;
          this.threadId = response.threadId || this.threadId;

          this.createMsg({
            msg: processed.answer,
            type: 'AI',
            model: response.model,
            suggestions: [],
            questions: ['Please tell more'],
            timeConsumed: response.timeConsumed,
          } as ITravelAssistantMessage);
        } else {
          this.createMsg({
            type: 'AI_ERROR',
          } as ITravelAssistantMessage);
        }
      });
  }

  generateUserId() {
    if (!this.anonymousUserId) {
      this.anonymousUserId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    }
    return this.anonymousUserId;
  }

  createGPTMsg(response) {
    const data = JSON.parse(response.data);
    this.createMsg({
      msg: data.answer,
      type: 'AI',
      model: response.model,
      questions: ['Please tell more', ...data.questions],
    } as ITravelAssistantMessage);
  }

  processMsg(response: IAIResponse): IAIResponse {
    response.answer = window['marked'].parse(response.answer);

    response.answer = response.answer.replaceAll('####', '###');

    // do not use bold until all messages are processed
    response.answer = response.answer.replaceAll(
      '<strong',
      '<strong class="font-normal"'
    );

    const pattern = /### (.+?)\n/g;

    const matches = response.answer.match(pattern);
    if (matches && matches.length) {
      for (const match of matches) {
        response.answer = response.answer.replace(match, match + '</h3>\n');
      }
    }

    // data.answer = data.answer.replace(/\s+/g, ' ');  // only keep single blank
    // data.answer = data.answer.replace(/<br>\s+<br>/g, '<br><br>');
    // data.answer = data.answer.replaceAll('<br>-', '<br><br>-');  // add a line break before each list item
    // data.answer = data.answer.replace(/<br>\d+\./g, '<br><br>$&');  // add a line break before each list item
    // data.answer = data.answer.replace(/(<br>)+/g, '<br><br>'); // replace multiple line breaks with a single one
    // data.answer = data.answer.replace(/<\/h3><br><br>/g, '</h3><br>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    // data.answer = data.answer.replace(/<br><br><\/h3>/g, '<br></h3>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    // data.answer = data.answer.replace(/&#39;/g, '\'');
    // data.answer = data.answer.replace(/&amp;/g, '&');
    // data.answer = data.answer.replace(/&quot;/g, '"');
    // data.answer = data.answer.replace(/\s<br><br>\s/g, '<br><br>');
    // data.answer = data.answer.replace(/<br><br> - /g, ' - ');
    // data.answer = data.answer.replace(/: -/g, ' -');
    // data.answer = data.answer.replace(/<br><br> <\/p>/g, '<br></p>');

    response.answer = response.answer
      .replace(/【.*?】/g, '')
      // .replace(/\s+/g, ' ')  // only keep single blank
      // // .replace(/<br>\s+<br>/g, '<br><br>')
      // // .replace(/(<br>)+/g, '<br><br>')
      // .replace(/&#39;/g, '\'')
      // .replace(/&amp;/g, '&')
      // .replace(/&quot;/g, '"')
      // .replace(/<br><br> <\/p>/g, '<br></p>')
      // .replaceAll('\n', '<br>')
      .replaceAll('###', '<h3>')
      .replaceAll('</h3><br></h3>', '</h3>')
      .replaceAll('</h3><br><br>', '</h3><br>')
      .replace(/(<h3>.*?<br>-)(?!<\/h3>)/g, '$1</h3>') // sometimes h3 doesn't have a closing tag, then add it
      .replaceAll('<br>-</h3>', '<br></h3>-')
      .replaceAll(' in the documents.', ' in my knowledge base.')
      .replaceAll(' in the uploaded documents.', ' in my knowledge base.')
      .replaceAll(' in the provided documentation.', ' in my knowledge base.')
      .replaceAll(
        ' among the provided documentation.',
        ' in my knowledge base.'
      )
      .replaceAll(' in the documentation.', ' in my knowledge base.')
      .replaceAll(' in the provided documents.', ' in my knowledge base.')
      .replaceAll(' within the provided documents.', ' in my knowledge base.')
      .replaceAll(' in the current documentation.', ' in my knowledge base.')
      .replaceAll(`Here's the relevant information in JSON format:`, '');

    try {
      // response.answer = JSON.parse(response.answer);
      // response.answer = window['marked']
      //   .parse(response.answer)
      //   .replace(/<\/?p>/g, '');

      // if (window['marked']) {
      //   response.answer = window['marked'].parse(response.answer);
      // } else {

      // for some version of iOS, the marked js cannot work, we parse the markdown here
      response.answer = response.answer.replaceAll('**', '*');
      const matches = response.answer.matchAll(/\*([^*]+)\*/g);

      for (const match of matches) {
        console.log('match[1]', match[1]);
        response.answer = response.answer.replaceAll(
          `*${match[1]}*`,
          `<b>${match[1]}</b>`
        );
      }

      console.log('replaced, ', response.answer);
      // }
    } catch (ex) {
      console.warn('parse error', ex.message);

      // if (window['marked']) {
      //   response.answer = window['marked'].parse(response.answer);
      // } else {
      //   // for some version of iOS, the marked js cannot work, we parse the markdown here
      //   response.answer = response.answer.replaceAll('**', '*');
      //   const matches = response.answer.matchAll(/\*([^*]+)\*/g);
      //
      //   for (const match of matches) {
      //     console.log('match[1]', match[1]);
      //     response.answer = response.answer.replaceAll(
      //       `*${match[1]}*`,
      //       `<em>${match[1]}</em>`
      //     );
      //   }
      //
      //   console.log('replaced, ', response.answer);
      // }
    }

    response.answer = response.answer.replaceAll(
      '<a href=',
      '<a target="_blank" href='
    );

    return response;
  }

  createErrorMsg(response) {
    this.createMsg({
      msg: response.model + ` didn't respond correctly`,
      type: 'AI',
      model: response.model,
    } as ITravelAssistantMessage);
  }

  removeLastOccurrence(str, substring) {
    const lastIndex = str.lastIndexOf(substring);

    if (lastIndex !== -1) {
      return str.slice(0, lastIndex) + str.slice(lastIndex + substring.length);
    }

    return str;
  }

  anonymousUse() {
    this.isShowLogin = false;
    this.isAppendLoginToChatArea = false;
    this.isAnonymous = true;
    this._localStorage.setItem('travelbuddy_is_anonmous_use', true);
    console.log('anonymous use -----------');
    this.send(true);
  }

  loginStatusChanged(status: boolean) {
    console.log('login status', status);
    this.userProfilePicture = status
      ? SocialLoginComponent.profilePicture
      : undefined;
    // this.isShowLogin = !SocialLoginComponent.isUserLoggedIn;

    this.emailErrorMsg = '';

    if (status && SocialLoginComponent.isUserLoggedIn) {
      // this.isShowLogin = false; // move to loadUserChatHistory, after loading user chat history
      this.isAppendLoginToChatArea = false;
      this.msgHistory = [];
      this.loadUserChatHistory(true);
      // this.send(true);  // move the send to loadUserChatHistory, after loading user chat history
    }
  }

  logout() {
    try {
      this.socialLogin.logout();
      this.userProfilePicture = undefined;
      this.userEmail = undefined;
      this._localStorage.removeItem('travelbuddy_user_email');
      this._localStorage.removeItem('travelbuddy_is_anonmous_use');
      this.threadId = undefined;
      this.assistantId = undefined;
      this.cdRef.detectChanges();
    } catch (error) {
      console.warn(error);
    }
  }

  backToLandScreen() {
    this.isShowLandscreen = true;
    this.hasChatViewShowed = true;
  }

  isImg(url: string) {
    if (!url) return false;
    return url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null;
  }
}
