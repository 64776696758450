<div *ngIf="apiLoaded$ | async" [class.ig-cluster-map]="useCluster" class="w-full h-full">

  @if (_mode === 'dark') {
  <google-map [center]="center" [options]="options" (mapClick)="mapClick($event)">
    @if (directionsResults$ | async; as directionsResults) {
    <map-directions-renderer [directions]="directionsResults" [suppressMarkers]="true"
      [options]="directionsMarkerOptions" />
    }

    @if (useCluster) {
    <map-marker-clusterer
      [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">
      <map-marker #markerElem="mapMarker" *ngFor="let marker of _markers" [position]="marker.position"
        [label]="marker.label" [title]="marker.title" [options]="marker.options"
        (mapClick)="markerClick(markerElem, marker)">
      </map-marker>
    </map-marker-clusterer>
    } @else {
    <map-marker #markerElem="mapMarker" *ngFor="let marker of _markers" [position]="marker.position"
      [label]="marker.label" [title]="marker.title" [options]="marker.options"
      (mapClick)="markerClick(markerElem, marker)">
    </map-marker>
    }

    <map-info-window>
      <div *ngIf="isShowEventInfoWindow" (click)="goToEvent(selectedMarker.marker.data)"
        class="relative transition-all cursor-pointer ig-map-info-window">
        <ig-image [src]="selectedMarker?.marker?.data?.cover?.source" class="block overflow-hidden"
          cls="aspect-[3/2] w-full transition-all"></ig-image>
        <div class="px-4 py-1 bg-white rounded-b-3xl">
          <div class="ig-map-info-win-name text-sm font-semibold text-gray-600 mb-1 ellipsis">
            {{ selectedMarker?.marker?.data?.name }}
          </div>
          <div class="text-xs text-gray-500 leading-3">
            <span>{{ selectedMarker?.marker.data?.timeTwoLines.date }}</span>
            <span
              *ngIf="selectedMarker?.marker.data?.eventTimes.length>1||selectedMarker?.marker.data?.timeTwoLines.time"
              class="mx-2">·</span>
            <span>{{
              selectedMarker?.marker?.data?.eventTimes?.length > 1 ? 'Multiple times'
              : selectedMarker?.marker?.data?.timeTwoLines?.time
              }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="isShowPlaceInfoWindow" (click)="goToPlace(selectedMarker.marker.data)"
        class="rounded-lg relative transition-all cursor-pointer ig-map-info-window">
        <ig-image [src]="selectedMarker?.marker?.data?.cover?.source" cls="aspect-[1/1] rounded-lg"></ig-image>
        <div class="w-full h-1/2 absolute bottom-0 left-0 rounded-lg bg-gradient-to-b from-transparent to-black">
          <div class="ig-map-info-title absolute bottom-0 w-full p-4 mobile:pb-2">
            <p class="text-sm text-white font-semibold leading-[1.25rem] mb-1">{{ selectedMarker?.marker?.data?.name }}
            </p>
            <p class="ig-map-info-category text-sm text-gray-300 leading-[1.15rem] max-lines-2">
              {{ getDisplayCategory(selectedMarker?.marker?.data) }}
            </p>
          </div>
        </div>
      </div>
    </map-info-window>
  </google-map>
  } @else {
  <google-map [center]="center" [options]="options" (mapClick)="mapClick($event)">
    @if (directionsResults$ | async; as directionsResults) {
    <map-directions-renderer [directions]="directionsResults" [suppressMarkers]="true"
      [options]="directionsMarkerOptions" />
    }

    <!--    <map-advanced-marker></map-advanced-marker>-->

    @if (useCluster) {
    <map-marker-clusterer (clusteringend)="clustererEnd($event)"
      [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">
      <map-marker #markerElem="mapMarker" *ngFor="let marker of _markers" [position]="marker.position"
        [label]="marker.label" [title]="marker.title" [options]="marker.options"
        (mapClick)="markerClick(markerElem, marker)">
      </map-marker>
    </map-marker-clusterer>
    } @else {
    <map-marker #markerElem="mapMarker" *ngFor="let marker of _markers" [position]="marker.position"
      [label]="marker.label" [title]="marker.title" [options]="marker.options"
      (mapClick)="markerClick(markerElem, marker)">
    </map-marker>
    }

    <map-info-window>
      <div *ngIf="isShowEventInfoWindow" (click)="goToEvent(selectedMarker.marker.data)"
        class="relative transition-all cursor-pointer ig-map-info-window">
        <ig-image [src]="selectedMarker?.marker?.data?.cover?.source" class="block overflow-hidden"
          cls="aspect-[3/2] w-full transition-all"></ig-image>
        <div class="px-4 py-1 bg-white rounded-b-3xl">
          <div class="ig-map-info-win-name text-sm font-semibold text-gray-600 mb-1 ellipsis">
            {{ selectedMarker?.marker?.data?.name }}
          </div>
          <div class="text-xs text-gray-500 leading-3">
            <span>{{ selectedMarker?.marker.data?.timeTwoLines.date }}</span>
            <span
              *ngIf="selectedMarker?.marker.data?.eventTimes.length>1||selectedMarker?.marker.data?.timeTwoLines.time"
              class="mx-2">·</span>
            <span>{{
              selectedMarker?.marker?.data?.eventTimes.length > 1 ? 'Multiple times'
              : selectedMarker?.marker?.data?.timeTwoLines.time
              }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="isShowPlaceInfoWindow" (click)="goToPlace(selectedMarker.marker.data)"
        class="rounded-lg relative transition-all cursor-pointer ig-map-info-window">
        <ig-image [src]="selectedMarker?.marker?.data?.cover?.source" cls="aspect-[1/1] rounded-lg"></ig-image>
        <div class="w-full h-1/2 absolute bottom-0 left-0 rounded-lg bg-gradient-to-b from-transparent to-black">
          <div class="ig-map-info-title absolute bottom-0 w-full p-4 mobile:pb-2">
            <p class="text-sm text-white font-semibold leading-[1.25rem] mb-1">{{ selectedMarker?.marker?.data?.name }}
            </p>
            <p class="ig-map-info-category text-sm text-gray-300 leading-[1.15rem] max-lines-2">
              {{ getDisplayCategory(selectedMarker?.marker?.data) }}
            </p>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="isShowClusterInfoWindow" class="w-full p-4">
        <div class="grid grid-cols-3 gap-3">
          @for (clusterPosition of clusterPositions; let i = $index; track i) {
          <div class="relative cursor-pointer" (click)="goToPlace(clusterPosition.place)">
            <div class="flex flex-col gap-2 items-center overflow-hidden">
              <img src="${placeMarker.data.cover.source}" class="w-full h-[8vh] rounded-md" />
              <p class="text-sm">${placeMarker.data.title}</p>
            </div>
          </div>
        </div> -->
    </map-info-window>
  </google-map>
  }
</div>
