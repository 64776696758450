<ig-accordion #accordion [mode]="mode" [title]="title" class="event-date-filter" [menuClass]="menuClass"
  (onIconClicked)="clear()">
  <ng-container expand>
    <ng-container *ngTemplateOutlet="eventDateFilter">
    </ng-container>
  </ng-container>

  <ng-container dropdown>
    <ng-container *ngTemplateOutlet="eventDateFilter">
    </ng-container>
  </ng-container>

  <ng-template #eventDateFilter>
    <button mat-menu-item (click)="changeDate('all')">ALL</button>
    <button mat-menu-item (click)="changeDate('today')" [class.date-selected]="title==='TODAY'">TODAY</button>
    <button mat-menu-item (click)="changeDate('tomorrow')" [class.date-selected]="title==='TOMORROW'">TOMORROW</button>
    <button mat-menu-item (click)="changeDate('this week')" [class.date-selected]="title==='THIS WEEK'">THIS
      WEEK</button>
    <button mat-menu-item (click)="changeDate('this weekend')" [class.date-selected]="title==='THIS WEEKEND'">THIS
      WEEKEND</button>
    <button mat-menu-item (click)="changeDate('this month')" [class.date-selected]="title==='THIS MONTH'">THIS
      MONTH</button>
    <button mat-menu-item (click)="$event.stopPropagation();isShowSpecificDates=true"
      [class.date-selected]="title.includes('FROM')">SELECT SPECIFIC DATES</button>

    <ig-date-range-picker #dateRangePicker *ngIf="isShowSpecificDates" (onRangeChanged)="dateRangeChanged($event)">
    </ig-date-range-picker>
  </ng-template>
</ig-accordion>
