import { Injectable, Provider, TemplateRef, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { isEmptyArray } from '../../utils/object.extensions';
import { LocalStorageService } from 'src/app/modules/shared/services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public static modalConfiguration: MatDialogConfig = {
    width: '600px',
  };

  constructor(
    private dialog: MatDialog,
    private _localStorageService: LocalStorageService
  ) {}

  public show<T>(config: {
    component: Type<T> | TemplateRef<T>;
    data?: any;
    providers?: Provider[];
    panelClass?: string | string[];
    backdropClass?: string;
    disableClose?: boolean;
  }) {
    let dialogRef = this.dialog.open(
      config.component,
      Object.assign({}, ModalService.modalConfiguration, {
        // data: config.providers,
        data: config.data, //config.providers,
        panelClass: config.panelClass,
        backdropClass: config.backdropClass,
        disableClose: config.disableClose,
      })
    );
    return dialogRef;
  }

  public injectStyle(
    selector: string,
    styles: string[] = [],
    onLoad: () => void
  ): void {
    let all = document.querySelectorAll(selector);
    if (isEmptyArray(all)) {
      let shadowRoot = (
        document.getElementById('imgoingcalendar-wrapper') ||
        document.getElementById('imgoingcalendar-wrapper-wix')
      )?.querySelector('app-root')?.shadowRoot;

      shadowRoot = shadowRoot || document.querySelector('app-root').shadowRoot;

      all = shadowRoot.querySelectorAll(selector);

      if (isEmptyArray(all)) {
        console.warn('No element found with the selector', selector);
        return;
      }
    }

    const styleUrl =
      this._localStorageService.getItem<string>('STYLES_URL') ||
      'https://localhost:4201/styles.css';
    console.log('styleUrl ----------------', styleUrl);

    let shadowRoot = Array.from(all)?.last()?.shadowRoot;

    if (!Array.from(all)?.length || !Array.from(all)?.last() || !shadowRoot) {
      const ele = document.querySelector(selector);
      if (ele) {
        shadowRoot = ele.shadowRoot;
      }
    }

    const linkElem = document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', styleUrl);

    linkElem.onload = () => {
      onLoad && onLoad();
    };

    shadowRoot.appendChild(linkElem);

    if (styles.length) {
      styles.forEach((style) => {
        const linkEle = document.createElement('link');
        linkEle.setAttribute('rel', 'stylesheet');
        linkEle.setAttribute('href', style);
        shadowRoot.appendChild(linkEle);
      });
    }
  }
}
