import {
  Component,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ButtonTriggerEvent } from 'src/app/common/components/button/button.component';
import { ImageLinkFileSelectorComponent } from 'src/app/common/components/image.link.file.selector/image.link.file.selector.component';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { IAppState } from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import {
  EditPlaceCategoryModalActions,
  EditPlaceCategoryModalComponent,
  IEditPlaceCategoryModalData,
} from '../edit.place.category.modal/edit.place.category.modal.component';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { catchError, finalize, of, switchMap, takeUntil, tap } from 'rxjs';
import { CreatePlaceCommand } from 'src/app/modules/shared/models/create.place.command';

@Component({
  selector: 'ig-create-place',
  templateUrl: './create.place.component.html',
  styleUrls: ['./create.place.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CreatePlaceComponent
  extends BaseComponent
  implements AfterViewInit
{
  form: FormGroup;
  categoryText = '';
  isCssReady = false;
  hasCopyrights = false;
  imageUploaders: number = 5;
  imgUploadType: 'upload' | 'link' | 'unset' = 'unset';

  uploadedImages: string[] = [];
  imageLinks: string = '';
  hint: string =
    'When entering multiple image URLs, please separate them with a semicolon. You can enter up to a maximum of 5.';

  constructor(
    public activatedRoute: ActivatedRoute,
    public placeService: VisitorPlaceService,
    public dialogRef: MatDialogRef<CreatePlaceComponent>,
    public modalService: ModalService,
    private _fb: FormBuilder,
    private _errorAlertService: ErrorAlertService,
    private _store: Store<IAppState>
  ) {
    super({ activatedRoute });

    this.resetForm();

    // handle browser back button click, close the event details view and reload the page
    window.addEventListener('popstate', this.hashChanged.bind(this));

    // as this modal is ShadowDom, we need to inject the styles into it
    dialogRef.afterOpened().subscribe(() => {
      modalService.injectStyle(
        '.cdk-overlay-container ig-create-place',
        [],
        () => {
          this.isCssReady = true;
        }
      );
    });
  }

  ngAfterViewInit(): void {
    this.checkUsingImgLink();
  }

  public get isFormInValid() {
    return this.form.invalid;
  }

  public get isUsingLink() {
    return !!this.form.get('imageLink').value;
  }

  public onImageUploaded(url: string) {
    console.log('Url:', url);
    if (this.isUsingLink) {
      this.form.get('imageLink').setValue(null);
    }

    if (this.uploadedImages.indexOf(url) === -1) {
      this.uploadedImages.push(url);
    }
  }

  public onRemoveImage(url: string) {
    if (this.uploadedImages.indexOf(url) > -1) {
      this.uploadedImages = this.uploadedImages.filter((x) => x !== url);
    }
  }

  submit(event: ButtonTriggerEvent) {
    const address = this.form.get('address').value;
    if (typeof address === 'string') {
      this._errorAlertService.showErrorAlert(
        'Please type the physical address and select one from the dropdown'
      );
      event.immediateComplete();
      return;
    }

    const imageLink = this.form.get('imageLink').value;
    if (imageLink && imageLink.split(';').length > 5) {
      this._errorAlertService.showErrorAlert(
        'You can only enter up to 5 image URLs.'
      );
      event.immediateComplete();
      return;
    }

    if (
      this.isUsingLink ||
      (this.uploadedImages && this.uploadedImages.length > 0)
    ) {
      if (!this.hasCopyrights) {
        this._errorAlertService.showErrorAlert(
          'Please confirm that you have the rights to use the images you are uploading.'
        );
        event.immediateComplete();
        return;
      }
    }

    if (this.form.invalid) {
      event.complete();
      return;
    }

    //this.form.get('imageLink').setValue(data.url);
    let command = <CreatePlaceCommand>this.form.value;
    command.imageLink = this.uploadedImages.join(';');

    this.placeService.createPlace(this.hubName, command).subscribe({
      next: (res) => {
        this.resetForm();
        event.success();
        // this.close();
      },
      error: (err) => {
        this._errorAlertService.showErrorAlert(err.message);
      },
    });
  }

  close() {
    this.dialogRef.close();
  }

  showEditCategoryModal() {
    let actions = <EditPlaceCategoryModalActions>{
      confirm: (event: ButtonTriggerEvent, category) => {
        // this.categoryText = category.categoryText;
        // const scategory = category.category;

        this.form.get('category').setValue(category.categoryText);
      },
    };

    this.modalService.show({
      component: EditPlaceCategoryModalComponent,
      // providers: [
      //   { provide: EditPlaceCategoryModalActions, useValue: actions },
      //   { provide: VisitorPlace, useValue: null },
      // ],
      panelClass: 'ig-modal-w-70p',
      data: {
        actions,
        place: {
          categoryList: this.form.get('category').value
            ? this.form
                .get('category')
                .value.split(',')
                .map((x) => this.toCode(x.trim()))
            : [],
        } as VisitorPlace,
      } as IEditPlaceCategoryModalData,
    });
  }

  toCode(category: string) {
    return this.placeService.toCategoryCode(category);
  }

  resetForm() {
    this.form = this._fb.group({
      name: [null, [Validators.required]],
      description: [null, []],
      address: [null, [Validators.required]],
      website: [null, []],
      facebookLink: [null, []],
      category: [null, [Validators.required]],
      phone: [null, []],
      imageLink: [null, []],
      imgFile: [null, []],
      ownerEmail: [null, []],
      ownerPhone: [null, []],
      hasCopyrights: [false, []],
    });
  }

  /**
   * handle browser back button click, close the view
   * only close the page is not enough, it will always have other loading issues for the script embedding solution
   * so force reload the page for now
   * */
  // @HostListener('window:popstate', ['$event'])
  private hashChanged($event: Event) {
    $event.preventDefault();
    let url = window.location.href.split('imgoing-place')[0];
    url = url.endsWith('?') ? url.substring(0, url.length - 1) : url;
    window.location.href = url;
    this.close();
  }

  private checkUsingImgLink() {
    this.form
      .get('imageLink')
      .valueChanges.pipe(
        tap((value) => {
          this.imageLinks = value;
        })
      )
      .subscribe();
  }
}
