<div class="ig-nested-menu-wrap">
  <button *ngIf="isRootNode" #trigger mat-menu-item [matMenuTriggerFor]="menu" id="ig-custom-default-place"
          [class.filtering]="isFiltering" (menuOpened)="menuOpened()" matRipple [matRippleCentered]="true"
          [matRippleColor]="'rgba(255, 255, 255, 0.1)'">
    <div class="flex justify-between items-center">
      <span class="ig-nested-title">
        {{ node ? node.title : _title }}</span>

      <mat-icon class="s1"
                *ngIf="(rootCustomCategoryFromURL&&_title===rootCustomCategoryFromURL.title)||isFiltering">{{
          isMenuOpened
            ?
            'expand_more' :
            'chevron_left'
        }}
      </mat-icon>
      <mat-icon class="s2" *ngIf="(rootCustomCategoryFromURL&&!isFiltering)"
                (click)="iconClicked($event)">{{ isMenuOpened ? 'expand_more' : 'chevron_left' }}
      </mat-icon>
      <!-- <mat-icon class="s2" *ngIf="(rootCustomCategoryFromURL&&_title!==rootCustomCategoryFromURL.title)&&isFiltering"
        (click)="iconClicked($event)">clear</mat-icon> -->
      <mat-icon class="s3" *ngIf="!rootCustomCategoryFromURL" (click)="iconClicked($event)">{{
          isFiltering ? 'clear' :
            isMenuOpened
              ?
              'expand_more' :
              'chevron_left'
        }}
      </mat-icon>

    </div>
  </button>

  <button *ngIf="!isRootNode" mat-menu-item #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
          (mouseenter)="openMenu(menuTrigger)" (mouseleave)="triggerLeave(menuTrigger)">
    {{ node.title }}
  </button>
</div>

<mat-menu #menu="matMenu" class="ig-nested-menu" (closed)="isMenuOpened=false">

  <!-- items.length === 1 is a single node from URL, then show its sub-categories in the dropdown menu -->
  @if (items.length === 1) {
    @if (items[0].subCategories.length) {
      <button mat-menu-item (click)="select(node)" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">All
      </button>

      <ng-container *ngFor="let sub of items[0].subCategories; let i = index">
        <ig-nested-menu *ngIf="isExpandable(sub); else menuItem" [node]="sub" [items]="sub.subCategories"
                        [isRootNode]="false" (onClick)="onClick.emit($event)"></ig-nested-menu>
        <ng-template #menuItem>
          <button mat-menu-item (click)="select(sub)" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">{{
              sub.title
            }}
          </button>
        </ng-template>
      </ng-container>
    } @else {
      <button mat-menu-item (click)="select(node)" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">All
      </button>
      <button mat-menu-item (click)="select(items[0])" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">{{
          items[0].title
        }}
      </button>
    }

  } @else {
    <!-- items.length > 1 usually stands for the entire custom categories -->

    <button mat-menu-item (click)="select(node)" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">All
    </button>
    <ng-container *ngFor="let sub of items; let i = index">
      <ig-nested-menu *ngIf="isExpandable(sub); else menuItem" [node]="sub" [items]="sub.subCategories"
                      [isRootNode]="false" (onClick)="onClick.emit($event)"></ig-nested-menu>
      <ng-template #menuItem>
        <button mat-menu-item (click)="select(sub)" (mouseenter)="subMenuEnter()" (mouseleave)="subMenuLeave()">{{
            sub.title
          }}
        </button>
      </ng-template>
    </ng-container>

  }
</mat-menu>
