<div *ngIf="isOneRow()" class="h-full">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

@if (isShowTravelBuddy) {
  @defer (on timer(2000)) {
    <ig-ai-buddy-place-listing></ig-ai-buddy-place-listing>
  }
}

<mat-sidenav-container *ngIf="!isOneRow() && !isAiAssistant()" id="imgoing-container" class="h-full"
                       [class.default-color]="isIE">

  <mat-sidenav #sideBar mode="over" class="w-[80%]">
    <ig-side-bar *ngIf="isMobile" (onClose)="sideBar.close()"></ig-side-bar>
  </mat-sidenav>
  <mat-sidenav-content class="h-full" *ngIf="frontendSettings$ | async as settings">
    <div class="page-header" *ngIf="isMobile">
      <mat-icon class="text-2xl" role="button" (click)="sideBar.open()">menu</mat-icon>
      <div class="text-2xl my-3">More Options</div>
    </div>
    <div class="h-full">
      <ig-top-bar *ngIf="isTopbar(settings)"></ig-top-bar>

      <div class="flex relative" [ngStyle]="{'height':isTopbar(settings)?'calc(100% - 96px)':'100%'}">
        <div *ngIf="isSidebar(settings)" class="flex-[3]">
          <ig-side-bar></ig-side-bar>
        </div>
        <div class="flex-[9] mobile:w-full">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- NormanOK uses in-app browser, which shows this browser check and cannot hide -->
<div *ngIf="!isMobile">
  <div *ngIf="isIE&&isShowUpgradeBrowser&&hubName!=='NormanOK'" class="browser-upgrade-wrap">
    Your web browser is out of date. Update to a modern browser (Chome or Firefox) for more security, speed and the
    best
    experience on this site.
    <button mat-flat-button class="bg-green-400" (click)="upgradeBrowser()">Upgrade</button>
    <button mat-flat-button class="ml-2 bg-amber-400" (click)="hideUpgradeBrowser()">ignore</button>
  </div>
</div>

<div *ngIf="frontendSettings && frontendSettings.status !== 'live'"
     class="w-full h-full flex justify-center items-center text-xl absolute top-0 left-0 z-10 bg-white">
  This account is disabled, please contact our team, emailing aline&#64;iti-digital.com or call us at 912-267-1558
</div>
