import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccordionComponent } from 'src/app/common/components/accordion/accordion.component';
import { BaseComponent } from 'src/app/common/components/base.component';
import { VisitorEventService } from '../../services/visitor.event.service';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import * as EventQueryCommandActions from 'src/app/modules/event-calendar/ngrx.stores/event.query.command/actions';
import { Observable, takeUntil } from 'rxjs';
import { eventQueryCommandSelector } from '../../ngrx.stores/event.query.command/selectors';
import { frontendSettingsSelector } from '../../../shared/ngrx.stores/frontend.settings/selectors';
import {
  CustomEventLocationFilterGroups,
} from '../../../shared/models/visitor.custom.settings';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'ig-event-location-filter',
  templateUrl: './event.location.filter.component.html',
  styleUrls: ['./event.location.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ig-place-location-filter',
  },
})
export class EventLocationFilterComponent extends BaseComponent {
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Input() menuClass: string = '';
  @ViewChild('accordion', { static: true }) accordion: AccordionComponent;
  isMenuOpened = false;

  initialTitle: string = 'FILTER BY LOCATION';
  displayTitle: string = 'FILTER BY LOCATION';
  isItemSelected = this.initialTitle !== this.displayTitle;

  @Input() set title(title: string) {
    this.displayTitle = title;
    if (!this.initialTitle) {
      this.initialTitle = this.displayTitle;
    }
    this.isItemSelected = this.initialTitle !== this.displayTitle;
  }

  locationFilter$: Observable<{ locality: string }[] | null>;
  locationFilterGroups: CustomEventLocationFilterGroups;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: VisitorEventService,
    protected _store: Store<IAppState>
  ) {
    super({ activatedRoute });

    // this.locationFilter$ = this.eventService.getLocationFilter(this.hubName);

    this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
      settings && (this.locationFilterGroups = settings.customSettings.customEventLocationFilterGroups);
    });

    // set title and selected outline
    this._store
      .pipe(takeUntil(this.isDestroyed), select(eventQueryCommandSelector))
      .subscribe((cmd) => {
        if (cmd?.locations) {
          console.log('cmd.locations', cmd.locations);
          const group = this.frontendSettings.customSettings.customEventLocationFilterGroups.eventGroups.find(x => x.cities === cmd.locations);
          group && (this.title = group.title);
        }
      });

    // // set title and selected outline
    // this._store
    //   .pipe(takeUntil(this.isDestroyed), select(eventQueryCommandSelector))
    //   .subscribe((cmd) => {
    //     if (cmd?.location) {
    //       this.locationFilter$.subscribe((filters) => {
    //         const location = filters.find(
    //           (filter) =>
    //             filter.locality.toLowerCase() === cmd.location.toLowerCase()
    //         );
    //         location && (this.title = location.locality);
    //       });
    //     }
    //   });
  }

  changeFilter(location?: { locality: string }) {
    this._store.dispatch(
      EventQueryCommandActions.setLocation({
        location: location?.locality,
      })
    );
    this.title = location?.locality || 'FILTER BY LOCATION';
  }


  selectTopGroup(group: { id: string, title: string, cities: string[] }) {
    if (group.cities.length == 1 && group.title == group.cities[0]) {
      this._store.dispatch(
        EventQueryCommandActions.setLocationGroups({
          locations: group.cities,
        })
      );
      this.title = group.title || 'FILTER BY LOCATION';
    }
  }

  selectGroup(group: { _id: string, title: string, cities: string[] }) {
    this._store.dispatch(
      EventQueryCommandActions.setLocationGroups({
        locations: group.cities,
      })
    );
    this.title = group.title || 'FILTER BY LOCATION';
  }

  selectCity(city: string) {
    this._store.dispatch(
      EventQueryCommandActions.setLocationGroups({
        locations: [city],
      })
    );
    this.title = city || 'FILTER BY LOCATION';
  }

  selectAll() {
    this._store.dispatch(
      EventQueryCommandActions.setLocationGroups({
        locations: [],
      })
    );
    this.title = 'FILTER BY LOCATION';
  }

  iconClick($event) {
    console.log('click', this.isItemSelected);
    if (this.isItemSelected) {
      $event.stopPropagation();
      this.selectAll();
    }
  }

  clear() {
    this.changeFilter();
  }

  menuOpened(trigger: MatMenuTrigger) {
    this.isMenuOpened = true;
    setTimeout(() => {
      const menu = document.querySelector('.ig-place-location-filter-menu');
      const entireTrigger = this.shadowRoot?.querySelector('.ig-place-location-filter');
      const rect = entireTrigger?.getBoundingClientRect();

      if (!menu || !rect) return;

      (menu as HTMLElement).style.width = rect.width + 'px';
      (menu as HTMLElement).style.maxWidth = rect.width + 'px';
    });
  }
}
