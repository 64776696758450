import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, delay, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import * as PlaceQueryCommandActions from 'src/app/modules/place-calendar/ngrx.stores/place.query.command/actions';
import { placeQueryCommandSelector } from '../../ngrx.stores/place.query.command/selectors';
import { FavoriteCustomPlace } from 'src/app/modules/shared/models/favorite.custom.place';
import {
  getRouteUrl,
  routeConfigs,
} from 'src/app/common/constants/route.config';
import { Location } from '@angular/common';
import { VisitorService } from 'src/app/modules/shared/services/visitor.service';

@Component({
  selector: 'ig-favorite-custom-place-filter',
  templateUrl: './favorite.custom.place.filter.component.html',
  styleUrls: ['./favorite.custom.place.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoriteCustomPlaceFilterComponent extends BaseComponent {
  title = 'FAVORITE PLACES';
  settings: IFrontendSettings | null = null;
  frontendSettings$: Observable<IFrontendSettings | null>;
  customPlaces: FavoriteCustomPlace[] = [];
  hasFavoriteCategoryFromUrl?: boolean;
  originLink: string = '';

  @Input() mode: 'expand' | 'dropdown' = 'dropdown';
  @Output() favSelected = new EventEmitter();

  constructor(
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private _store: Store<IAppState>,
    private _location: Location,
    public visitorService: VisitorService
  ) {
    super({activatedRoute});
    this._store
      .pipe(takeUntil(this.isDestroyed), select(frontendSettingsSelector))
      .subscribe((settings: IFrontendSettings) => {
        if (!settings) return;
        this.settings = settings;
        this.customPlaces = settings.customSettings.customPlaces;
      });

    // set title and selected outline
    this._store
      .pipe(takeUntil(this.isDestroyed), select(placeQueryCommandSelector))
      .subscribe((cmd) => {
        console.log('fav cmd', cmd);
        if (!cmd.type && !cmd.choice) {
          this.title = 'FAVORITE PLACES';
        }

        let originLink = this._location.path().split('ig-custom-places=')[0];
        if (originLink.endsWith('?') || originLink.endsWith('&')) {
          originLink = originLink.slice(0, -1);
        }
        this.originLink = originLink;

        if (cmd.type) {
          const btn = this.settings.customSettings.customPlaces.find(
            (btn) => btn._id === cmd.type
          );
          btn && (this.title = btn.btnText);

          this.hasFavoriteCategoryFromUrl = cmd.hasFavoriteCategoryFromUrl;
          if (cmd.hasFavoriteCategoryFromUrl) {
            this.customPlaces = [btn];
          } else {
            this.customPlaces = this.settings.customSettings.customPlaces;
          }
          if (btn && cmd.updateURL !== false) {
            const currentPath = this._location.path();
            if (currentPath.includes('ig-custom-places=')) {
              this._location.go(currentPath.split('ig-custom-places=')[0]
                + `ig-custom-places=${this.visitorService.convertToUrlSafeString(btn.btnText)}-${btn._id}`);
            } else {
              const connector = currentPath.includes('?') ? '&' : '?';
              this._location.go(currentPath + `${connector}ig-custom-places=${this.visitorService.convertToUrlSafeString(btn.btnText)}-${btn._id}`);
            }
          }
        }

        if (cmd.choice) {
          this.title =
            this.settings.customSettings.customEditorChoice.editorChoiceForPlace;
        }
      });
  }

  clear() {
    if (!this.hasFavoriteCategoryFromUrl) {
      this.gotoCategory();
    }
  }

  gotoCategory(btn?: FavoriteCustomPlace) {
    this.title = btn?.btnText || 'FAVORITE PLACES';
    this._store.dispatch(
      PlaceQueryCommandActions.setFavoritePlaceBtnId({
        favBtnId: btn?._id,
        hasFavoriteCategoryFromUrl: this.hasFavoriteCategoryFromUrl
      })
    );

    if(!btn) {
      this.originLink && this._location.go(this.originLink);
    }    

    // btn && this._location.go(this.originLink);
    // this.gotoCalendar();
    this.favSelected.emit();
  }

  gotoEditorChoice(text: string) {
    this.title = text;
    this._store.dispatch(
      PlaceQueryCommandActions.setIsChoice({
        choice: true,
      })
    );

    // this.gotoCalendar();
    this.favSelected.emit();
  }

  gotoCalendar() {
    this._router.navigateByUrl(
      getRouteUrl(routeConfigs.visitor.placeCalendar, {
        hubName: this.hubName,
      }),
      {skipLocationChange: this.noChangeUrlWhenRedirect}
    );
  }
}
