import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Output,
  Renderer2,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImageService } from 'src/app/common/services/image/image.service';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';

import jwt_decode from 'jwt-decode';
import { SocialLoginComponent } from '../social.login.component';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ig-google-login',
  templateUrl: './google.login.component.html',
  styleUrls: ['./google.login.component.scss'],
})
export class GoogleLoginComponent extends BaseComponent {
  public auth2: any;
  public profilePicture: string;
  public static isUserLoggedIn: boolean;
  public static googleUserId: string;
  public static profilePicture: string;
  public static email: string;
  public static userName: string;

  SCRIPT_PATH = `https://accounts.google.com/gsi/client`;

  // notify SocialLoginComponent when google library is loaded, then show Facebook login button too
  public static googleReady$ = new BehaviorSubject(false);

  constructor(
    public activatedRoute: ActivatedRoute,
    public imageService: ImageService,
    public scriptService: ScriptService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    super({ activatedRoute });
    try {
      this.loadGoogleLibrary();
    } catch (e) {
      console.warn(e);
    }
  }

  public loadGoogleLibrary() {
    if (!this.scriptService.isScriptExist(this.SCRIPT_PATH)) {
      const scriptElement = this.scriptService.loadJsScript(
        this._renderer,
        this.SCRIPT_PATH
      );
      scriptElement.onload = () => {
        console.log('Google login script loaded');
        // notify SocialLoginComponent when google library is loaded, then show Facebook login button
        GoogleLoginComponent.googleReady$.next(true);
        this.initializeGoogle();
      };
      scriptElement.onerror = () => {
        console.log('Could not load the script: ' + this.SCRIPT_PATH);
      };
    }
  }

  @HostListener('window:onGoogleLibraryLoad', ['$event'])
  public initializeGoogle() {
    console.log('initializing google');

    // check if user is already logged in from local storage, as Google cannot remember login status
    const googleUser = localStorage.getItem('social.login.googleUser');
    if (googleUser) {
      const googleUserObj = JSON.parse(googleUser);
      console.log('googleUserLoaded in LocalStorage', googleUserObj);
      GoogleLoginComponent.isUserLoggedIn = true;
      GoogleLoginComponent.googleUserId = googleUserObj.userId;
      GoogleLoginComponent.profilePicture = googleUserObj.profilePicture;
      GoogleLoginComponent.email = googleUserObj.email;
      GoogleLoginComponent.userName = googleUserObj.userName;
      SocialLoginComponent.loginStatus$.next(true);
    }

    google['accounts'].id.initialize({
      client_id: '665289826545-463bhl103plveo5l05b94vjnenmr3f7e',
      callback: (data) => {
        console.log('google initialized', data);

        if (data['credential']) {
          const credential = jwt_decode(data['credential']);
          console.log('credential', credential);
          this.profilePicture = credential['picture'];
          GoogleLoginComponent.isUserLoggedIn = true;
          GoogleLoginComponent.googleUserId = credential['email'] + '|google';
          GoogleLoginComponent.email = credential['email'];
          GoogleLoginComponent.userName = credential['name'];
          GoogleLoginComponent.profilePicture = this.profilePicture;
          console.log(`googleId: ${GoogleLoginComponent.googleUserId}`);

          // Google cannot remember login status, so save it in localStorage
          localStorage.setItem(
            'social.login.googleUser',
            JSON.stringify({
              userId: GoogleLoginComponent.googleUserId,
              profilePicture: GoogleLoginComponent.profilePicture,
              email: GoogleLoginComponent.email,
              userName: GoogleLoginComponent.userName,
            })
          );
          SocialLoginComponent.loginStatus$.next(true);
        }
      },
    });

    const loginBtnItems = (this.shadowRoot || this._document).querySelectorAll(
      '.google-signin-btn'
    );
    console.log('loginBtnItems', loginBtnItems);

    const loginBtnItemsInPopup = this._document.querySelectorAll(
      '.cdk-overlay-container .google-signin-btn'
    );
    console.log('loginBtnItemsInPopup', loginBtnItemsInPopup);

    Array.from(loginBtnItems)
      .concat(Array.from(loginBtnItemsInPopup))
      .forEach((el) => {
        google['accounts'].id.renderButton(el, {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          width: window.innerWidth >= 400 ? 295 : 260,
        });
      });

    // google['accounts'].id.renderButton(
    //   this.shadowRoot.getElementById('google-signin-btn'),
    //   { type: 'standard', theme: 'outline', size: 'large', width: 295 }
    // );
  }

  public login() {}

  public go() {
    GoogleLoginComponent.isUserLoggedIn = true;
    GoogleLoginComponent.googleUserId = '117302739800456246841|google';
    GoogleLoginComponent.profilePicture =
      'https://lh3.googleusercontent.com/a/AGNmyxbLIc5wFVwhFt0S3nko5iUrXK4tCh1gLndHliWk=s96-c';
    SocialLoginComponent.loginStatus$.next(true);
  }

  public logout() {
    if (GoogleLoginComponent.isUserLoggedIn) {
      google['accounts'].id.disableAutoSelect();
      GoogleLoginComponent.isUserLoggedIn = false;
      GoogleLoginComponent.googleUserId = null;
      GoogleLoginComponent.profilePicture = null;
      GoogleLoginComponent.email = null;
    }
    localStorage.removeItem('social.login.googleUser');
    SocialLoginComponent.loginStatus$.next(false);
    // hide Facebook login button, as Google login button needs to be initialized again
    // GoogleLoginComponent.googleReady$.next(false);
    // this.scriptService.removeScript(this.SCRIPT_PATH);
  }

  public get isUserLoggedWithGoogle() {
    return GoogleLoginComponent.isUserLoggedIn;
  }
}
