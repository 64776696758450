<div class="flex h-[88px] relative bg-[#eee] px-5 2xl:px-6 3xl:px-10 ig-topbar"
  [class.script-desktop-sm]="mediaQueryService.isDesktopSm$ | async"
  [class.script-desktop-lg]="mediaQueryService.isDesktopLg$ | async"
  [class.script-desktop-xl]="mediaQueryService.isDesktopXl$ | async"
  [class.script-desktop-2xl]="mediaQueryService.isDesktop2Xl$ | async"
  [class.script-desktop-3xl]="mediaQueryService.isDesktop3Xl$ | async">
  <div class="ig-pos-wrap min-w-[190px] relative flex-[3] 2xl:max-w-[200px] 2xl:pl-0">
    <div class="ig-viewing ext-gray-700 text-sm mt-4">YOU ARE NOW VIEWING</div>
    <div class="ig-at text-gray-600 text-3xl font-semibold">{{command.isEventCalendar ? 'EVENTS' :
      'PLACES'}}</div>
  </div>
  <div class="flex-[16] 2xl:flex-[14] flex gap-4 justify-center items-center btn-wrap">
    <ng-container *ngIf="command.isEventCalendar">
      <ig-event-date-filter mode="dropdown" menuClass="ig-date-filter"
        (dateSelected)="filterEventsByDate($event)"></ig-event-date-filter>
      <ig-event-location-filter *ngIf="settings.toggle.eventLocationFilter" mode="dropdown"></ig-event-location-filter>
      <ig-favorite-custom-event-filter mode="dropdown"></ig-favorite-custom-event-filter>
    </ng-container>

    <ng-container *ngIf="!command.isEventCalendar">
      <ig-custom-default-place-category-filter [isOnlyShowNodeFromUrl]="true"></ig-custom-default-place-category-filter>
      <ig-favorite-custom-place-filter></ig-favorite-custom-place-filter>
      <ig-place-location-filter *ngIf="settings.toggle.placeLocationFilter" mode="dropdown"></ig-place-location-filter>
    </ng-container>

    <ig-button class="1200:block hidden" (trigger)="viewChange()" [icon]="command.isMapView ? 'list' : 'room'">
      <span class="text-sm">{{command.isMapView ? 'LIST VIEW' : 'VIEW ON MAP'}}</span>
    </ig-button>
    <div class="text-center max-w-[40px] hidden 2xl:block">OR</div>

    <ig-search-field [value]="searchText" label="search {{command.isEventCalendar ? 'events' : 'places'}}"
      [formControl]="searchControl" class="mt-[11px] 1340:block hidden"></ig-search-field>
    <ig-button class="1440:block hidden" (trigger)="addEventsManually()" icon="input">
      <span class="text-sm">SUBMIT YOUR {{command.isEventCalendar ? 'EVENT' : 'PLACE'}}</span>
    </ig-button>


    <div class="1440:hidden h-11 w-11 max-w-[44px] bg-white shadow-md rounded-md cursor-pointer"
      matTooltip="More options">
      <div #matMenuTrigger #trigger trigger [matMenuTriggerFor]="menu" aria-label="open"
        class="flex items-center px-4 justify-between text-sm p-4 h-11 rounded-md outline-orange-400 outline-offset-2 outline-4"
        matRipple [matRippleCentered]="true" [matRippleColor]="'rgba(255, 255, 255, 0.1)'"
        [class.outline]="searchControl.value">
        <mat-icon class="text-gray-500 text-lg relative right-0.5">more_vert</mat-icon>
      </div>

      <mat-menu #menu="matMenu" class="ig-accordion-menu ig-tb-more {{menuClass}}">

        <button class="1200:!hidden ig-default-btn-color ig-menu-btn" mat-menu-item
          (click)="viewChange()">{{command.isMapView ? 'CLICK FOR LIST
          VIEW' :
          'CLICK FOR MAP VIEW'}}</button>
        <div class="1200:!hidden text-center mt-4 mb-2 text-gray-400">- OR -</div>
        <div class="1340:!hidden mb-5">
          <ig-search-field [value]="searchText" label="search {{command.isEventCalendar ? 'events' : 'places'}}"
            [formControl]="searchControl" class="mt-[11px]"></ig-search-field>
        </div>
        <button mat-menu-item class="!mt-2 ig-default-btn-color ig-menu-btn" (click)="addEventsManually()">SUBMIT YOUR
          {{command.isEventCalendar ?
          'EVENT'
          :
          'PLACE'}}</button>

      </mat-menu>
    </div>
    <ig-powered-by class="flex justify-end"></ig-powered-by>
  </div>

</div>

<div class="w-full h-2 mb-2 bar-underline"></div>
