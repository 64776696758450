import { PlaceType } from './place.type';

export class CalendarActionCommand {
  public queryCommand = new CalendarQueryCommand();
  public isEventCalendar = true;
  public isMapView = false;
}

export class CalendarQueryCommand {
  public page?: number = 0;
  public limit?: number = getRequestLimit();//16;
  public from?: Date;
  public to?: Date;
  public category? = 'All';
  public categoryList?: string[]; // for custom default place categories
  public categoryNode?: PlaceType; // for custom default place categories
  public search?: string;
  public choice?: boolean;
  public favoriteEventBtnId?: string;
  public updateURL?: boolean;   // first time landing to a custom favorite place/event page, it doesn't need to add the ig-custom-places/ig-custom-events query string to URL (as client may create a dedicated page for that)
  public type?: string; // custom favorite place id
  public source? = 'google';
  public isForMapView?: boolean;
  public isForSearchView?: boolean;
  public isForUpcoming?: boolean;
  public fromIndex?: number;
  public location?: string; // for location filter
  public locations?: string[]; // for location filter groups  
  public hasFavoriteCategoryFromUrl?: boolean;// for favorite place categories
  public useFuzzyQuery?: boolean = true;
  // public id?: string;
}

const getRequestLimit = () => {
  console.log('window.innerWidth', window.innerWidth);
  return window.innerWidth >= 2560 ? 36 : 16;
};
